<script>
import {email, required} from "vuelidate/lib/validators";
import FlagButton from '@/components/FlagButton.vue';
import axios from 'axios'
import {userService} from "@/helpers/user.service"
import {useUserStore} from "@/store/user";
import {errorCatcher} from "@/helpers/error-catcher";
import {routerHelper} from "@/helpers/router-helper";
import Swal from "sweetalert2";
import {logoHelper} from "@/helpers/logo-helper";
import InformationPanelRender from "@/components/account/information-panel-render.vue";
import CustomLoginMethods from "@/components/account/custom-login-methods.vue";

export default {

  components: {CustomLoginMethods, InformationPanelRender, FlagButton},

  data() {
    return {
      email: "",
      password: "",
      submitted: false,
      loginEmail: false,

      registered: false
    };
  },

  computed: {
    logoHelper() {
      return logoHelper
    },
    getImageSize() {
      let screenWidth = screen.width;
      let width = 60;
      if (screenWidth > 576 && screenWidth <= 992) {
        width = 35;
      }

      return width;
    },

    getVideoWidth() {
      let screenWidth = screen.width;
      if (screenWidth >= 576 && screenWidth <= 992) {
        return 200
      }

      if (screenWidth <= 1400) {
        return 500
      }

      return screen.width / 2;
    },

    user: () => useUserStore().getUser
  },

  setup() {
    return {
      userService
    }
  },

  created() {
    this.catchError()

    document.body.classList.add("auth-body-bg");

    const urlSearchParams = new URLSearchParams(window.location.search);
    const urlParams = Object.fromEntries(urlSearchParams.entries());
    if (urlParams.registered) {
      this.registered = true
    }
  },

  validations: {
    email: {required, email},
    password: {required}
  },

  methods: {

    catchError() {
      const params = routerHelper.params();
      if (!params.oauth2_error) {
        return;
      }

      const oauth2_error = atob(params.oauth2_error)
      if (!oauth2_error) {
        return;
      }

      Swal.fire("Błąd!", errorCatcher.catchErrors({
        response: {
          data: {
            error: JSON.parse(oauth2_error)
          }
        }
      }), "error");
    },

    tryToLogIn() {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();

      if (!this.$v.$invalid) {
        const {email, password} = this;
        if (email && password) {
          this.login();
        }
      }
    },

    login() {
      const json = JSON.stringify({
        email: this.email,
        password: this.password
      });

      axios.post(`/user/session`, json, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
      }).then(({data}) => {
        this.$bvToast.toast(this.$t("login.success"), {
          title: this.$t('message.success'),
          variant: 'success',
          solid: true
        });

        const {authorized} = data
        if (!authorized) {
          window.location = "/verify-new-session"
          return
        }

        this.submitted = false;
        userService.login(true);
      }).catch((error) => {
        errorCatcher.catchErrors(error)
        this.submitted = false;
      })
    }
  },
};
</script>

<template>
  <div>
    <div class="home-btn d-none d-inline-flex">
      <a href="https://discord.gg/ecat" target="_blank" class="btn btn-sm p-3 px-1 font-size-24 header-item">
        <i class="ri ri-discord-fill"></i>
      </a>
      <FlagButton :drop-left="true"></FlagButton>
      <button @click="$store.dispatch('theme/switchTheme').then(() => $store.dispatch('theme/updateTheme'))"
              type="button" class="btn btn-sm px-3 font-size-24 header-item">
        <i :class="$store.getters['theme/isThemeDark'] ? 'ri ri-sun-line' : 'ri ri-moon-line'"
           style="color: #ffffff;"></i>
      </button>
    </div>
    <div>
      <div class="container-fluid p-0">
        <div class="row no-gutters">
          <div class="col-xl-4">
            <div class="authentication-page-content p-4 d-flex align-items-center min-vh-100">
              <div class="w-100">
                <div class="row justify-content-center">
                  <div class="col-xl-9">
                    <div>
                      <div class="text-center">
                        <div>
                          <a href="/" class="logo">
                            <img :src="logoHelper.logo()" :height="getImageSize" alt="logo"/>
                          </a>
                        </div>

                        <h4 class="font-size-18 mt-4">{{ $t('landing-page-title') }}</h4>
                        <p class="text-muted">
                          {{ user ? $t('login.logged-as', {"email": user.email}) : $t('login.subtitle') }}
                          <template v-if="user">
                            <a @click="userService.logout"
                               class="clickable-element font-weight-medium text-primary">{{
                                $t('navbar.dropdown.logout')
                              }}</a>
                          </template>
                        </p>
                      </div>

                      <div class="p-2 mt-5">
                        <b-alert
                            v-model="registered"
                            class="mt-3 text-center"
                            variant="success"
                            dismissible>
                          Konto zostało pomyślnie utworzone!<br>
                          Zaloguj się, aby rozpocząć proces aktywacji konta
                        </b-alert>

                        <template v-if="user">
                          <div class="text-center">
                            <router-link to="/dashboard/home" class="btn btn-primary w-md waves-effect waves-light">
                              {{ $t('login.panel') }}
                            </router-link>
                          </div>
                        </template>
                        <template v-else>
                          <form class="form-horizontal" @submit.prevent="tryToLogIn">
                            <template v-if="!loginEmail">
                              <custom-login-methods/>

                              <div class="my-4 text-center d-flex flex-row align-items-center">
                                <div class="line"/>
                                <div class="px-3">lub</div>
                                <div class="line"/>
                              </div>

                              <div class="text-center">
                                <button
                                    class="btn btn-primary w-100 waves-effect waves-light button-icon"
                                    type="submit"
                                    @click="loginEmail = true"
                                >
                                  <i class="mdi mdi-email-outline"></i>
                                  {{ $t("login-methods.with-email") }}
                                </button>
                              </div>

                              <div class="py-4">
                                <div class="text-center mb-4">
                                  <p>{{ $t('login.forgot-password') }}
                                    <router-link
                                        tag="a"
                                        to="/forgot-password"
                                        class="font-weight-medium text-primary"
                                    >{{ $t('login.reset-password') }}
                                    </router-link>
                                  </p>
                                </div>

                                <div class="text-center">
                                  <p>
                                    {{ $t('login.no-account') }}
                                    <router-link
                                        tag="a"
                                        to="/register"
                                        class="font-weight-medium text-primary"
                                    >{{ $t('login.register') }}
                                    </router-link>
                                  </p>
                                </div>
                              </div>

                            </template>
                            <template v-else>
                              <div class="form-group auth-form-group-custom mb-4">
                                <i class="ri-mail-line auti-custom-input-icon"></i>
                                <label for="email">Email</label>
                                <input
                                    type="email"
                                    v-model="email"
                                    class="form-control"
                                    id="email"
                                    :placeholder="$t('message.type', { 'type': 'email'})"
                                    :class="{ 'is-invalid': submitted && $v.email.$error }"
                                />
                                <div v-if="submitted && $v.email.$error" class="invalid-feedback">
                                  <span v-if="!$v.email.required">{{ $t('message.required') }}</span>
                                  <span v-if="!$v.email.email">{{ $t('message.email-error') }} </span>
                                </div>
                              </div>

                              <div class="form-group auth-form-group-custom mb-4">
                                <i class="ri-lock-2-line auti-custom-input-icon"></i>
                                <label for="userpassword">{{ $t('message.password') }}</label>
                                <input
                                    v-model="password"
                                    type="password"
                                    class="form-control"
                                    id="userpassword"
                                    :placeholder="$t('message.type', { 'type': $t('message.password').toLowerCase()})"
                                    :class="{ 'is-invalid': submitted && $v.password.$error }"
                                />
                                <div
                                    v-if="submitted && !$v.password.required"
                                    class="invalid-feedback">{{ $t('message.required') }}
                                </div>
                              </div>

                              <div class="custom-control custom-checkbox mb-4">
                                <input
                                    type="checkbox"
                                    class="custom-control-input"
                                    id="customControlInline"
                                />
                                <label
                                    class="custom-control-label"
                                    for="customControlInline"
                                >{{ $t('login.remember-me') }} </label>
                              </div>

                              <div class="text-center mb-4">
                                <button
                                    class="btn btn-primary w-100 waves-effect waves-light"
                                    type="submit"
                                >{{ $t('login.logIn') }}
                                </button>
                              </div>

                              <div class="text-center mb-4">
                                <p>{{ $t('login.forgot-password') }}
                                  <router-link
                                      tag="a"
                                      to="/forgot-password"
                                      class="font-weight-medium text-primary"
                                  >{{ $t('login.reset-password') }}
                                  </router-link>
                                </p>
                              </div>

                              <template v-if="!user">
                                <div class="text-center">
                                  <p>
                                    {{ $t('login.no-account') }}
                                    <router-link
                                        tag="a"
                                        to="/register"
                                        class="font-weight-medium text-primary"
                                    >{{ $t('login.register') }}
                                    </router-link>
                                  </p>
                                </div>
                              </template>

                              <div class="my-4 text-center d-flex flex-row align-items-center">
                                <div class="line"/>
                                <div class="px-3">lub</div>
                                <div class="line"/>
                              </div>

                              <custom-login-methods/>
                            </template>
                          </form>
                        </template>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-8">
            <information-panel-render/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

