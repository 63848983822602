import store from "@/state/store";

export const brandLogoHelper = {
    logoGoogle,
    logoFacebook,
    logoApple
};

const googleLogoDark = require('@/assets/images/brands/google-icon-dark.svg');
const googleLogoLight = require('@/assets/images/brands/google-icon-light.svg');
const fbLogoDark = require('@/assets/images/brands/facebook-icon-dark.svg');
const fbLogoLight = require('@/assets/images/brands/facebook-icon-light.svg');
const appleLogoDark = require('@/assets/images/brands/apple-icon-dark.svg');
const appleLogoLight = require('@/assets/images/brands/apple-icon-light.svg');


function logoGoogle() {
    return store.getters["theme/isThemeDark"] ? googleLogoDark : googleLogoLight
}

function logoFacebook() {
    return store.getters["theme/isThemeDark"] ? fbLogoDark : fbLogoLight
}

function logoApple() {
    return store.getters["theme/isThemeDark"] ? appleLogoDark : appleLogoLight
}